import React from "react"
import { /* graphql, */ Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
//import useViewportWidth from "../hooks/useViewportWidth"
import LoginBlock from "../components/LoginBlock";
import LoginThirdParty from "../components/LoginThirdParty";
import { string } from "../util/strings";
import UserInteraction from "../components/user-interaction";

const IndexPage = ({ data, ...props }) => {

  const seo = { title:'כניסת משתמשים' }

  //const width = useViewportWidth()
  //const isMobile = width <= 768
  //console.log('width, isMobile = ', width, isMobile)

  return (
    <Layout>
      <Seo {...seo} />
      {/* <Hero className="border mt-[126px] bg-hero " title={'התחברות'} subtitle={''} bgImage={{ publicURL: '' }} /> */}
      <div className=""></div>
      <section className=" mt-[90px] lg:mt-[126px] lg:pt-12 pt-4 pb-12 bg-gray-500">
        <UserInteraction
          title={string('login.title')}
          className="lg:bg-[url(/backgrounds/pattern5.svg)] bg-white"
        >

          <div className="">
            <LoginThirdParty />
          </div>


          <div className=" text-center lg:py-4 flex flex-row items-center">
            <div className="grow h-px bg-yellow" />
            <div className="px-2" >או</div>
            <div className="grow  h-px bg-yellow" />
          </div>

          <div className=" text-center ">
            <LoginBlock />
          </div>


          <section className="py-12">
            <div className="container text-center">
              <div className=" text-blue p-2">אין לך חשבון משתמש ? </div>
              <Link to={'/register'} className="border-yellow border px-6 py-2  inline-block hover:bg-yellow transition">אני רוצה להרשם</Link>
            </div>
          </section>

        </UserInteraction>
      </section>

    </Layout >
  )
}

export default IndexPage
/*
const Hero = ({ title, subtitle, bgImage, className, ...props }) => {

  return (
    <section
      className={' bg-no-repeat bg-center-top py-12 lg:py-28 lg:px-32  ' + className}
      style={{ backgroundImage: `linear-gradient(rgba(11, 11, 11, 0.5), rgba(11, 11, 11, 0.5)), url('${bgImage.publicURL}')` }}
    >

      <div className="mx-auto text-white text-center">
        <h1 className="text-[28px] lg:text-[115px] -text-blue">{title}</h1>
        <h2 className="text-base lg:text-[50px] -max-w-[600px] -border leading-tight	" dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </section>
  )
}
 */



export const Head = () => <title>כניסה</title>
