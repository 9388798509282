import React from "react";
import LoginForm from "../LoginForm";
import { useDispatch, useSelector } from "react-redux";



const LoginBlock = () => {

  const dispatch = useDispatch();
  const u = useSelector((s) => s.user);
  const { loading } = u;

  const error = u.messages.length > 0 ? u.messages[0] : "";

  //const [errorMsg, setErrorMsg] = useState("");

  const doLogin = (values) => {
    const data = {
      username: values.email,
      password: values.password,
      type: "custom",
    };
    //send(data)
    dispatch({ type: "user/login", payload: data });
  };


  const reset = () => {
    dispatch({ type: "user/logout", payload: {} });
  }


  return (
    <div className="">
      {error && (
        <div>
          <p className="text-danger">{error}</p>
        </div>
      )}

      <div className="">
        <LoginForm onSubmit={doLogin} message={error} loading={loading} />
        <button disabled className="hidden" onClick={reset} >reset</button>
      </div>
    </div>
  );
};

export default LoginBlock;
