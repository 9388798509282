import React from "react";
//import ReactDOM from 'react-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "gatsby";

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
  const errors = {};

  const required = 'שדה נדרש'

  if (!values.password) {
    errors.password = required;
  }

  if (!values.email) {
    errors.email = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "כתובת מייל אינה חוקית";
  }

  return errors;
};

const CommentForm = ({ onSubmit, message, loading }) => {

  //submit handler

  return (
    <div className="">

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={validate}
        onSubmit={async (values) => {
          onSubmit(values)
        }}
      >
        {({ isValid/* , errors */ }) => (
          <Form className="space-y-4">

            <div className="space-y-4 max-w-[382px] mx-auto mb-6">
              <div className="">
                <label htmlFor="email" className="form-label hidden">
                  אימייל *
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3 w-full max-w-[382px]"
                  style={{ direction: "RTL" }}
                  placeholder="אימייל"
                />
                <ErrorMessage name="email" component="div" className="error text-danger text-red-800" />
              </div>


              <div className="mb-3 ">
                <label htmlFor="password" className="form-label hidden">
                  סיסמה
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3  w-full max-w-[382px]"
                  placeholder="סיסמה"
                />
                <ErrorMessage name="password" component="div" className="error text-danger text-red-800" />
              </div>

              <div className="text-right">
                <Link to={'/recover-password'} className="text-blue">שכחתי סיסמה</Link>
              </div>


            </div>
            <button type="submit" className="text-lg p-3 w-100 bg-navy text-white font-bold hover:bg-blue  min-w-[260px] transition " disabled={loading || !isValid}>
              כניסה למערכת {" "}
              {loading &&
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Loading...</span>
                </>
              }
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CommentForm;
