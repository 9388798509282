import React, { useState } from "react";
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//import GoogleLogin from "react-google-login";
import { useConfig } from "../../util/config";
import { useDispatch } from "react-redux";
import { string } from "../../util/strings";
//import { FcGoogle } from "react-icons/fc";
//import { ImFacebook } from "react-icons/im";
import ImFacebook2 from "../../images/facebook.svg";

const ThirdPartyLogin = () => {

  const config = useConfig();
  const dispatch = useDispatch();
  const [errorMsg2, setErrorMsg2] = useState("");

  //const userStorage = useContext(UserContext)

  /*
    {
      "first_name": "Barak",
      "last_name": "Galili",
      "email": "barak@e-kugel.com",
      "id": "10225120869021600",
      "accessToken": "EAAq1c0UXonMBAOk0N5T8yZB9X1xr4a8wVvceczmWveFTthPQpiRo0bxeLoayFMzscfYzNVa84ZBuMZB5NkJdYZAWwaLfAqxWU6ZCxewwjVHXG6UprXZCBzoX14grYjrU9JDGN1SmVOLw0gwNZAcGV6ycJ7TzQzRZA0M3ZAlU8J3ZB5L5SoKZBnv7B6rdEwOf6DDSft8yn8zMpKjTDPuEwuoUotR",
      "userID": "10225120869021600",
      "expiresIn": 6903,
      "signedRequest": "23OQJmCBSYO4TDWjDgrh8Phbx9kuEbks-pxY6ZLLS_U.eyJ1c2VyX2lkIjoiMTAyMjUxMjA4NjkwMjE2MDAiLCJjb2RlIjoiQVFEMS1OTThPbWRXdDRadDR4bFJsQV9heHJFeGJzY0NLTVJwV19rTmI3amMyeWlRZEhjeHJBcWNhZnhweGlibS1kWHdhOWRpMUJaQnNndDMxN2J5Q2JaODRmZi1RN1RUX3ZPSTQtVzlVcExrTU01djlSVzlZbFBDd0ZocFJQZGhXTV9TNTNXSUl1cG85TE5ZbkhPc0F4Q2J3ek1ydGtmcUhJLW9URWRaUC1fbnF4eURrZkRodno1anJFdUwxUmw4eHdtZTlfeTdYR3V4c0VGNzlQVHRfcmRRN1NKRUJOOUtkR2g5VGVzUTlwTmtMZC0xOEY0VlZtb1N2Y2N2eWJObGljQ1NzbEVjT1ZTbE1uN1JSbXNLeFZfckFDZHNaN0tYRjVEa3FZSmJacHRyWTg1U190dDV3dkhTd21qem1ZNDZualk3YzNnSVFGOEFqb01rTTNZUjE3d0IiLCJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImlzc3VlZF9hdCI6MTYzNTA3NzA5N30",
      "graphDomain": "facebook",
      "data_access_expiration_time": 1642853097
    }
  */
  const responseFacebook = (response) => {
    const data = {
      accessToken: response.accessToken,
      first_name: response.first_name,
      last_name: response.last_name,
      type: "fb",
    };

    dispatch({ type: "user/login", payload: data });
  };

  /* const responseGoogle = (response) => {
    const profile = response.profileObj;

    const data = {
      accessToken: response.accessToken,
      first_name: profile.givenName,
      last_name: profile.familyName,
      type: "google",
    };

    dispatch({ type: "user/login", payload: data });
  }; */

  /*
    {
      details: "Not a valid origin for the client: http://localhost:8000 has not been registered for client ID 298241480964-pg3ktuc9i4ihb0mro5dprjj8h21bkp58.apps.googleusercontent.com. Please go to https://console.developers.google.com/ and register this origin for your project's client ID.",
      error: "idpiframe_initialization_failed"
    }
  */
  /* const googleOnFailure = (response) => {
    setErrorMsg2(response.error);
  }; */

  const facebookOnFailure = (response) => {
    //console.log(response);
    setErrorMsg2("תקלת פייסבוק");
  };

  return (
    <div className="auth-third-party container text-center">
      {/* <h3 className="fs-4">{string('login.login with')}</h3> */}

      {errorMsg2 && (
        <div>
          <p className="text-danger text-red-400">{errorMsg2}</p>
        </div>
      )}

      <div className="inner  flex justify-center space-x-8 space-x-reverse py-0 lg:py-2" >
        <div className=" ">
          <FacebookLogin
            //style={{ width: "100%" }}
            appId={config.facebook_app_id}
            fields="name,email,picture"
            callback={responseFacebook}
            onFailure={facebookOnFailure}
            render={(renderProps) => <FacebookButton {...renderProps} />}
          />
        </div>
        {/*
        <div className=" ">
          <GoogleLogin
            clientId={config.google_clent_id}
            //buttonText="LOGIN WITH GOOGLE"
            onSuccess={responseGoogle}
            onFailure={googleOnFailure}
            render={(renderProps) => <GoogleButton {...renderProps} />}
          />
        </div> */}
      </div>
    </div>
  );
};
/*
const GoogleButton = (props) => {

  const { onClick, disabled } = props;
  return (
    <button
      className="p-3 w-64 border border-grey2 flex flex-row items-center hover:bg-yellow"
      onClick={onClick}
      disabled={disabled}
    >
      <FcGoogle className="mx-4" size={24} />
      {" "}
      {string('login.login using google')}
    </button>
  );
}; */

const FacebookButton = (props) => {
  //console.log(props);
  const { onClick, disabled } = props;
  return (
    <button
      className="p-3 w-64 border border-grey2 text-center -flex flex-row items-center hover:bg-blue hover:text-white transition"
      onClick={onClick}
      disabled={disabled}
    >
      {/* <ImFacebook className="mx-4" size={24} /> */}
      <div>
        <img src={ImFacebook2} alt="facebook" className="inline px-2" />

        {string('login.login using facebook')}
      </div>
    </button>
  );
};

export default ThirdPartyLogin;
